import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { carouselImages } from "../utils/constants";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const animationVariants = [
  {
    initial: { scale: 0.3 },
    animate: { scale: 1 },
    exit: { scale: 0.3 },
  },
  {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  {
    initial: { rotate: -45 },
    animate: { rotate: 0 },
    exit: { rotate: 45 },
  },
  {
    initial: { y: -70 },
    animate: { y: 0 },
    exit: { y: -70 },
  },
  // Add more animation variants as needed
];

const textArray = [
  "BUSINESS INTELLIGENCE",
  "CONSULTING & OUTSOURCING",
  "MOBILE APPLICATION",
  "WEB APPLICATION",
];

const DemoCarousel = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, carouselImages.length, page);
  const [currentDot, setCurrentDot] = useState(0);

  // Use useEffect to start the auto-pagination timer when the component mounts
  React.useEffect(() => {
    const timerId = setInterval(() => {
      paginate(1);
    }, 5000); // 5000 milliseconds = 5 seconds

    // Clear the timer when the component unmounts
    return () => clearInterval(timerId);
  }, []);

  const paginate = (newDirection) => {
    setPage((prevState) => {
      const newPage = prevState[0] + newDirection;
      return [newPage, newDirection];
    });
    setCurrentDot((prevDot) => {
      const newDot = prevDot + newDirection;
      return wrap(0, textArray.length - 1, newDot);
    });
  };

  const handleDotClick = (index) => {
    if (index !== currentDot) {
      const newDirection = index < currentDot ? -1 : 1;
      paginate(newDirection);
    }
  };

  const currentAnimationVariant =
    animationVariants[imageIndex % animationVariants.length];

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <div className="carouselContainer">
          <motion.img
            className="carouselImage"
            key={page}
            src={carouselImages[imageIndex]}
            alt={textArray[imageIndex]}
            custom={direction}
            initial={currentAnimationVariant.initial}
            animate={currentAnimationVariant.animate}
            exit={currentAnimationVariant.exit}
            transition={{
              ease: "linear",
              duration: 0.5,
              x: { duration: 1 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
          <AnimatePresence>
            {textArray[imageIndex] && (
              <motion.div
                initial={{
                  y: -50,
                  opacity: 0.5,
                }}
                animate={{ y: 0, opacity: 1 }}
                exit={{
                  x: -1000,
                  opacity: 0.3,
                  transition: {
                    ease: "easeOut",
                    duration: 1,
                  },
                }}
                transition={{ ease: "easeIn", duration: 1.4 }}
                className="imageText"
                key={textArray[imageIndex]}
              >
                {textArray[imageIndex]}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </AnimatePresence>
      <div className="dots">
        {textArray.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentDot ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
      <div className="next" onClick={() => paginate(1)}>
        <ArrowForwardIosIcon fontSize="80px" />
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        <ArrowForwardIosIcon fontSize="80px" />
      </div>
    </>
  );
};

export default DemoCarousel;
