import FolderIcon from "@mui/icons-material/Folder";
import { Card } from "@mui/material";
import classes from "./TechCard.module.css";
import { Link } from "react-router-dom";

const TechCard = ({ techData }) => {
  return (
    <div>
      {techData.map((item) => (
        <Card className={classes.techCard} key={item.id}>
          <div className={classes.techHead}>
            <FolderIcon />
            <h4 className={classes.techHeadingText}>{item.title}</h4>
          </div>
          <div>
            <ul className={classes.techList}>
              {item.list.map((data) => (
                <li className={classes.techListInner} key={data.title}>
                  <Link
                    to={`${
                      item.title.toLowerCase().includes("services")
                        ? `/services/${data.link}`
                        : item.title.toLowerCase().includes("industries")
                        ? `/industries/${data.link}`
                        : `/technologies/${data.link}`
                    }`}
                    key={data.title}
                    className={classes.linkser}
                  >
                    {data.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default TechCard;
