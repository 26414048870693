import SlickCarousel from "../Reusable/SlickCarousel";
import classes from "./Services.module.css";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { serviceList } from "../utils/constants";
import Banner from "../Reusable/Banner";

const Services = () => {
  return (
    <>
      <Banner
        page="Services"
        videoUri="https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Clean%20LineStyle%20Logo_1080p%20(1).mp4?alt=media&token=3c6c7040-ea6b-4655-8284-5c6238b2f46e&_gl=1*zkkkv4*_ga*MjAwNzk0NjY0NC4xNjY4MjQ1MjM2*_ga_CW55HF8NVT*MTY5NjAwNTgyMC4yNjMuMS4xNjk2MDA1OTEzLjUxLjAuMA.."
      />
      <SlickCarousel
        type="services"
        heading="Our Services"
        slideList={serviceList}
      />
    </>
  );
};

export default Services;
