import { Grid } from "@mui/material";
import Banner from "../Reusable/Banner";
import DetailCard from "../Reusable/DetailCard";
import classes from "./Details.module.css";
import TechCard from "../Reusable/TechCard";
import {
  industryList,
  serviceList,
  subCardData,
  technologiesList,
} from "../utils/constants";
import { useLocation } from "react-router-dom";
import React, { useMemo } from "react";

const Details = ({ page, imgUrl }) => {
  const { pathname } = useLocation();

  const data = useMemo(() => {
    if (pathname.includes("services")) {
      return serviceList.find((service) => pathname.includes(service.link));
    } else if (pathname.includes("industries")) {
      return industryList.find((industry) => pathname.includes(industry.link));
    } else {
      return technologiesList.find((tech) => pathname.includes(tech.link));
    }
  }, [pathname]);

  return (
    <>
      <Banner page={page} imgUrl={imgUrl} />
      <div className={classes.detailContainer}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <DetailCard data={data} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TechCard techData={subCardData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(Details);
