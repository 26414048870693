import classes from "./PrivacyPolicy.module.css";

const TermsConditions = () => {
  return (
    <>
      <div className={`${classes.PrivacyPolicy}`}>
        <h1 className={classes.privacytitle}>
          Terms and Conditions for Enable BI Software Solutions Pvt. Ltd.
        </h1>
      </div>
      <div className={classes.points}>
        <h3 className={classes.title}>1. Acceptance of Terms</h3>
        <p className={classes.privacycontent}>
          By accessing or using any software, products, or services provided by
          Enable BI Software Solutions Pvt. Ltd. you agree to be bound by these
          terms and conditions. If you do not agree with any part of these
          terms, please do not use our software, products, or services.
        </p>
        <h3 className={classes.title}>2. Software License</h3>
        <p className={classes.privacycontent}>
          2.1. License Grant: Subject to compliance with these terms, Enable BI
          Software Solutions Pvt. Ltd. grants you a limited, non-exclusive,
          non-transferable, and revocable license to use our software for its
          intended purpose.
        </p>
        <p className={classes.privacycontent}>
          2.2. Restrictions: You may not reverse engineer, decompile,
          disassemble, or attempt to derive the source code of our software. You
          may not sublicense, lease, rent, or transfer your license to any third
          party.
        </p>
        <p className={classes.privacycontent}>
          2.3. Updates: Enable BI Software Solutions Pvt. Ltd. may provide
          updates or new versions of the software. Your continued use of the
          software may require you to install these updates.
        </p>
        <h3 className={classes.title}>3. Privacy and Data Collection</h3>
        <p className={classes.privacycontent}>
          3.1. Privacy Policy: Our privacy policy, available on our website,
          explains how we collect, use, and protect your personal information.
          By using our software, you consent to the terms of our privacy policy.
        </p>
        <p className={classes.privacycontent}>
          3.2. Data Collection: We may collect data related to your use of the
          software, which may include, but is not limited to, usage statistics
          and error reports. This data helps us improve our software and
          services.
        </p>
        <h3 className={classes.title}>4. Payment and Fees</h3>
        <p className={classes.privacycontent}>
          4.1. Fees: If you purchase any of our software or services, you agree
          to pay the applicable fees as described on our website or in any
          contract or invoice.
        </p>
        <p className={classes.privacycontent}>
          4.2. Payment Information: You agree to provide accurate and complete
          payment information and authorize us to charge your payment method for
          any fees incurred.
        </p>

        <h3 className={classes.title}>5. Intellectual Property</h3>
        <p className={classes.privacycontent}>
          5.1. Ownership: Enable BI Software Solutions Pvt. Ltd. retains all
          rights, title, and interest in and to the software, including all
          intellectual property rights.
        </p>
        <p className={classes.privacycontent}>
          5.2. Feedback: If you provide feedback, suggestions, or ideas
          regarding our software, you grant us a non-exclusive, royalty-free,
          worldwide, perpetual, and irrevocable right to use, disclose,
          reproduce, and modify that feedback without any obligation to you.
        </p>
        <h3 className={classes.title}>6. Termination</h3>
        <p className={classes.privacycontent}>
          We reserve the right to terminate or suspend your access to our
          software or services, with or without notice, for any reason,
          including, but not limited to, violation of these terms.
        </p>
        <h3 className={classes.title}>7. Warranty and Disclaimer</h3>
        <p className={classes.privacycontent}>
          7.1. No Warranty: Our software is provided "as is" without warranties
          of any kind, either express or implied. We do not guarantee that the
          software will be error-free, uninterrupted, or secure.
        </p>
        <p className={classes.privacycontent}>
          7.2. Limitation of Liability: Enable BI Software Solutions Pvt. Ltd.
          shall not be liable for any indirect, special, incidental,
          consequential, or punitive damages, or for any loss of profits or
          revenue, arising out of or in connection with the use or inability to
          use our software or services.
        </p>
        <h3 className={classes.title}>8. Governing Law and Jurisdiction</h3>
        <p className={classes.privacycontent}>
          These terms and conditions are governed by and construed in accordance
          with the laws of the Republic of India. Any disputes arising out of or
          in connection with these terms and conditions shall be subject to the
          exclusive jurisdiction of the courts located in Vijayawada, Andhra
          Pradesh, India, where Enable BI Software Solutions Pvt. Ltd. is
          registered.
        </p>
        <h3 className={classes.title}>9. Contact Information</h3>
        <p className={classes.privacycontent}>
          If you have any questions or concerns regarding these terms and
          conditions, please contact us at Enable BI Software Solutions Pvt.
          Ltd.
        </p>
      </div>
    </>
  );
};

export default TermsConditions;
