import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className={`${classes.PrivacyPolicy} row container-fluid`}>
        <div className={`${classes.content1} col-md-6`}>
          <h1 className={classes.privacytitle}>
            Privacy Policy for Enable BI Software Solutions Pvt. Ltd.
          </h1>
        </div>
        <div className={`${classes.content1} col-md-6`}>
          <p className={classes.privacytitle}>
            Effective Date: 28th August 2023
          </p>
        </div>
      </div>
      <div className={classes.points}>
        <h3 className={classes.title}>1. Introduction</h3>
        <p className={classes.privacycontent}>
          Welcome to Enable BI Software Solutions Pvt. Ltd. We are committed to
          protecting your privacy and ensuring that your personal information is
          handled securely and responsibly. This privacy policy outlines how we
          collect, use, disclose, and safeguard your personal information when
          you use our software, products, or services.
        </p>
        <h3 className={classes.title}>2. Information We Collect</h3>
        <p className={classes.privacycontent}>
          2.1. Personal Information: We may collect personal information, such
          as your name, email address, contact details, and any other
          information you provide to us voluntarily.
        </p>
        <p className={classes.privacycontent}>
          2.2. Usage Information: We collect information about how you interact
          with our software and services, including device information, log
          data, and analytics.
        </p>
        <h3 className={classes.title}>3. How We Use Your Information</h3>
        <p className={classes.privacycontent}>
          3.1. To Provide Services: We use your personal information to provide
          and improve our software and services, communicate with you, and
          respond to your requests.
        </p>
        <p className={classes.privacycontent}>
          3.2. To Personalize User Experience: We may use your information to
          tailor our software and services to your preferences and needs..
        </p>
        <h3 className={classes.title}>4. Sharing Your Information</h3>
        <p className={classes.privacycontent}>
          We do not sell, rent, or trade your personal information to third
          parties. We may share your information in the following circumstances:
        </p>
        <p className={classes.privacycontent}>
          4.1. Service Providers: We may share information with trusted service
          providers who assist us in delivering our services.
        </p>
        <p className={classes.privacycontent}>
          4.2. Legal Compliance: We may disclose your information when required
          by law, legal process, or government authorities.
        </p>
        <h3 className={classes.title}>5. Data Security</h3>
        <p className={classes.privacycontent}>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction. However, no data transmission over the internet can be
          guaranteed to be entirely secure.
        </p>
        <h3 className={classes.title}>6. Cookies and Tracking</h3>
        <p className={classes.privacycontent}>
          We may use cookies and similar tracking technologies to enhance your
          user experience and for analytics purposes. You can manage your cookie
          preferences through your browser settings.
        </p>
        <h3 className={classes.title}>7. Your Rights</h3>
        <p className={classes.privacycontent}>
          You have certain rights concerning your personal information,
          including the right to access, correct, or delete your data. To
          exercise these rights, please contact us using the information
          provided below.
        </p>
        <h3 className={classes.title}>8. Updates to this Privacy Policy</h3>
        <p className={classes.privacycontent}>
          We may update this privacy policy to reflect changes in our practices
          or for legal reasons. We will notify you of any significant changes by
          posting the updated policy on our website.
        </p>
        <h3 className={classes.title}>9. Contact Us</h3>
        <p className={classes.privacycontent}>
          If you have any questions or concerns about this privacy policy,
          please contact us at:
        </p>
        <p className={classes.privacycontent}>
          Enable BI Software Solutions Pvt. Ltd.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
