import { Link, useLocation } from "react-router-dom";
import classes from "./ContactForm.module.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = (props) => {
  const selectedCategory=useLocation();
  const {selectedValue} =selectedCategory.state ?? {};
  const firstNameRef = useRef();
  const subjectRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const commentRef = useRef();
  const categoryRef=useRef();

  console.log('selectedValue',selectedValue)

  const clearInputValues = () => {
    firstNameRef.current.value = "";
    subjectRef.current.value = "";
    emailRef.current.value = "";
    phoneRef.current.value = "";
    commentRef.current.value = "";
    categoryRef.current.value="select";

  };

  const submitHandler = (e) => {
    e.preventDefault();

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^\d{10}$/;

    const formData = {
      from_name: "Enable BI Website",
      to_name: "Enable BI Admin",
      name: firstNameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      subject: subjectRef.current.value,
      comment: "category-comment:' " + categoryRef.current.value + "!' "+ commentRef.current.value,
      // category:categoryRef.current.value
    };

    console.log("fromData",formData)

    if (
      formData.name.trim().length === 0 ||
      formData.email.trim().length === 0 ||
      formData.phone.trim().length === 0 ||
      formData.subject.trim().length === 0 ||
      formData.comment.trim().length === 0 
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!phoneRegex.test(formData.phone)) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }
    
    // send formData to email js
    emailjs
      .send(
        // "service_4eoue7q",
        "service_info@enablebi",
        "template_ipb9zv4",
        formData,
        "5n2rNTLXeBV9akRVA"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          alert("Successfully Submitted");
          clearInputValues();
          // console.log(formData);
        },
        function (error) {
          console.log("FAILED...", error);
          alert("Network issue!Please try after sometime");
        }
      );
  };

  return (
    <>
      <section className={`${classes.Contactmain}`}>
        <div className={`${classes.ContactForm} row container`}>
          <div className={`${classes.contacttextfield} col-md-8`}>
            <div className={`${classes.container} `}>
              <div className={`${classes.headingform} `}>
                <h3 className={classes.aboutheading}>
                  <i className={`${classes.file} fa-solid fa-file-lines`}></i>
                  Contact Form
                </h3>
              </div>
              <div className={classes.Form}>
                <form className={`${classes.form} row container`}>
                  <div className={`${classes.column} col-md-6`}>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Your Name"
                      className={classes.input}
                      ref={firstNameRef}
                      required
                    />
                  </div>
                  <div className={`${classes.column} col-md-6`}>
                    <input
                      type="email"
                      id="mail"
                      name="mail"
                      placeholder="Email"
                      className={classes.input}
                      ref={emailRef}
                      required
                    />
                  </div>
                  <div className={`${classes.column} col-md-6`}>
                    <input
                      type="tel"
                      id="pname"
                      name="pname"
                      placeholder="Phone Number"
                      className={classes.input}
                      ref={phoneRef}
                      required
                    />
                  </div>
                  <div className={`${classes.column} col-md-6`}>
                    <input
                      type="text"
                      id="lname"
                      name="lname"
                      placeholder="Subject"
                      className={classes.input}
                      ref={subjectRef}
                      required
                    />
                  </div>
                  <div className={`${classes.column} col-md-12`}>
                    <select  className={classes.input} value={selectedValue} ref={categoryRef}>
                      <option selected disabled value='select' >select category</option>
                      <option value='Graduates'> Graduates</option>
                      <option value='Experienced Professionals'> Experienced Professionals</option>
                      <option value='Students/ Internships'> Students/ Internships</option>
                      <option value='Explore Opportunities by Location'> Explore Opportunities by Location</option>
                    </select>
                    {/* <input
                      type="text"
                      id="lname"
                      name="lname"
                      
                      className={classes.input}
                      ref={subjectRef}
                      required
                    /> */}
                  </div>
                  <div className={`${classes.column} col-md-12`}>
                    <textarea
                      className={classes.input}
                      id="desc"
                      name="desc"
                      rows="4"
                      cols="50"
                      placeholder="Comment"
                      ref={commentRef}
                      required
                    ></textarea>
                    <Link
                      to=""
                      className={classes.submit}
                      onClick={submitHandler}
                    >
                      Send
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={`${classes.contactdetails} col-md-4   `}>
            <div className={`${classes.container} `}>
              <div className={`${classes.details}`}>
                <div className={`${classes.headingform} `}>
                  <h3 className={classes.aboutheading}>
                    <i className={`${classes.file} fa-solid fa-book`}></i>
                    Contact Information
                  </h3>
                </div>
                <div className={`${classes.address}`}>
                  <p className={classes.add}>
                    <span className={classes.darkadd}>India Address : </span>
                    No 3/161-19-7-6-1, 2nd Main, SBI Colony, Madanapalle,
                    Annamayya (D.T), A.P - 517325
                  </p>
                  <p className={classes.add}>
                    <span className={classes.darkadd}>Canada Address : </span>
                    36 Mugford cres, Brampton, ON, L6Y6A7
                  </p>

                  <p className={classes.add}>
                    <span className={classes.darkadd}>Phone No : </span>
                    +91 7259589758
                  </p>
                  <p className={classes.add}>
                    <span className={classes.darkadd}>Email : </span>
                    info@enablebi.com
                  </p>
                </div>
              </div>
            </div>
            {/* <div className={`${classes.container} mt-4 `}>
              <div className={`${classes.details}`}>
                <div className={`${classes.headingform} `}>
                  <h3 className={classes.aboutheading}>
                    <i className={`${classes.file} fa-solid fa-book`}></i>
                    Follow Us
                  </h3>
                </div>
                <div className={`${classes.social}`}>
                  <i
                    className={`${classes.file} fa-solid fa-square-share-nodes`}
                  ></i>
                  <i className={`${classes.file} fa-solid fa-book`}></i>
                  <i className={`${classes.file} fa-solid fa-book`}></i>
                  <i className={`${classes.file} fa-solid fa-book`}></i>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
