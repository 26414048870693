import classes from "./HardWorking.module.css";

const HardWorking = () => {
  return (
    <>
      <div className={`${classes.HardWorking} row container-fluid`}>
        <h4 className={`${classes.hardheading} container`}>
          We are the most Reliable and Hard Working
        </h4>
        <div className={`${classes.HardWorking} row container`}>
          <div className={`${classes.Hardcontent1} col-md-6`}>
            <p className={`${classes.para1}`}>
              Our guiding principle centers around 'Unwavering Customer
              Satisfaction, positioning us as a customer-centric organization
              that fosters one-on-one interactions rather than purely
              business-to-business transactions. Our expertise lies in several
              key domains, including Business Intelligence, Consulting &
              Outsourcing, Web Development, App Development, and Custom
              Development. These pillars form the foundation of our commitment
              to excellence. Our company operates with the advantage of being
              privately funded, eliminating the dependence on venture capital.
              We maintain a consistent and sustainable level of profitability,
              enabling us to sustain and expand our operations solely through
              our existing revenue streams.
            </p>
          </div>
          <div className={`${classes.Hardcontent2} col-md-6`}>
            <p className={classes.para1}>
              Our services are characterized by a meticulous focus on detail, a
              culture of innovation and creativity, cost-effectiveness,
              unwavering reliability, transparency, punctual project delivery,
              and the achievement of exceptional outcomes. Our commitment is to
              ensure that you receive precisely what you require, without
              overextending your budget or providing less than the attention you
              rightfully deserve. Distinguished from other agencies, we furnish
              a comprehensive scope of work well in advance of any contractual
              commitments. Our projects are delivered free of defects, with a
              dedicated single point of contact for each client. We adhere to a
              strict timeline, consistently delivering high-quality services at
              competitive rates.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HardWorking;
