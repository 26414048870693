import SlickCarousel from "../Reusable/SlickCarousel";
import { technologiesList } from "../utils/constants";
import Banner from "../Reusable/Banner";

const Technologies = () => {
  return (
    <>
      <Banner
        page="Technologies"
        videoUri="https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Evolving%20Sketch%20Logo%20Reveal_1080p%20(1).mp4?alt=media&token=b2bdc755-796a-418a-8c98-82ffe8217877&_gl=1*1ozdc2x*_ga*MjAwNzk0NjY0NC4xNjY4MjQ1MjM2*_ga_CW55HF8NVT*MTY5NjAwNTgyMC4yNjMuMS4xNjk2MDA1OTA0LjYwLjAuMA.."
      />
      <SlickCarousel
        type="technologies"
        heading="Technologies"
        slideList={technologiesList}
      />
    </>
  );
};

export default Technologies;
