import React from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import classes from "./Banner.module.css";
import BannerVideo from "./BannerVideo";

const Banner = ({ videoUri, page, imgUrl }) => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);

  // Define the breadcrumb links and text based on the URL path
  let breadcrumb = (
    <div className={classes.iconcontent}>
      <Link to="/" className={classes.home}>
        Home
      </Link>
      {pathSegments.map((segment, index) => (
        <React.Fragment key={segment}>
          <ArrowForwardIosIcon className={classes.arrowicon} />
          <Link to={`/${segment}`} className={classes.subPage}>
            {segment}
          </Link>
        </React.Fragment>
      ))}
    </div>
  );

  // Modify the breadcrumb based on specific conditions
  // if (pathSegments.length === 0 || pathSegments[0] !== "services") {
  //   breadcrumb = (
  //     <div className={classes.iconcontent}>
  //       <Link to="/" className={classes.home}>
  //         Home
  //       </Link>
  //       <ArrowForwardIosIcon className={classes.arrowicon} />
  //       <Link to="/services" className={classes.subPage}>
  //         Services
  //       </Link>
  //     </div>
  //   );
  // }

  // Modify the breadcrumb for "industries" and "technologies" segments
  if (
    pathSegments[0] === "services" ||
    pathSegments[0] === "industries" ||
    pathSegments[0] === "technologies"
  ) {
    breadcrumb = (
      <div className={classes.iconcontent}>
        <Link to="/" className={classes.home}>
          Home
        </Link>
        <ArrowForwardIosIcon className={classes.arrowicon} />
        <Link to={`/${pathSegments[0]}`} className={classes.subPage}>
          {pathSegments[0]}
        </Link>
        {pathSegments[1] && (
          <ArrowForwardIosIcon className={classes.arrowicon} />
        )}
        <Link
          to={`/${pathSegments[0]}/${pathSegments[1]}`}
          className={classes.subPage}
        >
          {pathSegments[1]}
        </Link>
      </div>
    );
  }

  const titleStyles = {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    textAlign: "center",
    // background: "rgba(0, 0, 0, 0.7)", // Background color for better readability
    padding: "10px",
    color: "#fff", // Text color
  };

  // Return the banner component with the generated breadcrumb
  return (
    <div className={classes.servicesMain}>
      {videoUri ? (
        <BannerVideo videoUri={videoUri} />
      ) : (
        <img className={classes.servicesImage} src={imgUrl} alt={page} />
      )}
      <div className={classes.servicesText}>
        <div className="title" style={titleStyles}>
          <h3 className={classes.bannerHeading}>{page}</h3>
          {breadcrumb}
        </div>
      </div>
    </div>
  );
};

export default Banner;
