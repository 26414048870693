const BannerVideo = ({ videoUri }) => {
  return (
    <>
      <div class="video-wrapper">
        <video playsinline muted loop autoPlay>
          <source src={videoUri} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default BannerVideo;
