import Card from "@mui/material/Card";
import classes from "./MissionCard.module.css";

const MissionCard = () => {
  return (
    <Card className={classes.missionCard}>
      <h4 className={classes.missionHeading}>Mission</h4>
      {/* <ul className={classes.textList}>
        <li>
          Quickly adopt emerging technologies to carve out a niche for Macroserv
          Techno Solutions
        </li>
        <li>
          Move up the value chain by specializing in technologies and business
          verticals
        </li>
        <li>Create a highly reduntant human resource supply chain</li>
        <li>
          Grow to a stage where customers evangelize Macroserv Techno Solutions
        </li>
      </ul> */}
      <p className={classes.cardText}>
        Our mission is to be a catalyst for data-driven transformation,
        fostering a culture of informed decision-making and continuous
        improvement in organizations worldwide. By achieving this mission, we
        help businesses thrive and adapt in an increasingly data-driven world,
        ultimately contributing to their long-term success.
      </p>
    </Card>
  );
};

export default MissionCard;
