import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect } from "react";
import Slider from "react-slick";
import classes from "./SlickCarousel.module.css";
import { Link, useLocation } from "react-router-dom";

const SlickCarousel = ({ type, heading, slideList }) => {
  const { pathname } = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // useEffect(() => {
  //   // Calculate the maximum height among all cards
  //   let maxHeight = 420;

  //   const cards = document.querySelectorAll(`.${classes.card}`);
  //   cards.forEach((card) => {
  //     const cardHeight = card.clientHeight;
  //     if (cardHeight > maxHeight) {
  //       maxHeight = cardHeight;
  //     }
  //   });

  //   // Set the height of all cards to the maximum height
  //   cards.forEach((card) => {
  //     card.style.height = `${maxHeight}px`;
  //   });
  // }, [slideList]);

  return (
    <div className={`${classes.slider} container`}>
      <h2 className={classes.overallheading}> {heading} </h2>

      <Slider {...settings} className={classes.slidercarsouel}>
        {slideList.map((item) => (
          <div key={item.title}>
            <div
              className={`${classes.card} card h-auto `}
              style={{ margin: "0 10px" }}
            >
              <img
                src={`./images/${item.img}`}
                alt={item.title}
                className={classes.imgtestautomation}
              />
              <div className={`${classes.cardbody} card-body`}>
                <h3>
                  <Link
                    to={
                      pathname.includes(type)
                        ? `${item.link}`
                        : `${type}/${item.link}`
                    }
                    className={classes.title}
                  >
                    {item.title}
                  </Link>
                </h3>
                <p className={classes.desc}>{item.desc}</p>
                <Link
                  to={
                    pathname.includes(type)
                      ? `${item.link}`
                      : `${type}/${item.link}`
                  }
                  className={classes.readmore}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlickCarousel;
