import React from "react";
import classes from "./Career.module.css";
import CareerBanner from "../Component/Contactus/CareerBanner";
import { Link } from "react-router-dom";

const Career = () => {

  return (
    <div>
      <CareerBanner />
      <div className={classes.container}>
        <p>
        Empower your career with Enable BI – where innovation meets expertise. Join our dynamic team, drive impact, and unlock your potential in the world of Business Intelligence. #EnableYourCareer 
        </p>
      </div>

      <div className={classes.card}>
        <Link to='/contact-us' state={{selectedValue:'Graduates'}} className={classes.cardLink}>
          {" "}
          <div
            className={classes.graduate}
            style={{ backgroundColor: "#e48629" }}
          >
            {" "}
            <img
              src="./images/graduation.png"
              alt=""
              className={classes.cardIcon}
            />{" "}
            <h4 style={{ fontWeight: "bold" }}>Graduates</h4>
          </div>{" "}
        </Link>
        <Link to="/contact-us" className={classes.cardLink}  state={{selectedValue:'Experienced Professionals'}}>
          {" "}
          <div
            className={classes.graduate}
            style={{ backgroundColor: "rgb(32, 129, 226)" }}
          >
            <img
              src="./images/experienced.png"
              alt=""
              className={classes.cardIcon}
            />
            <h4 style={{ fontWeight: "bold" }}>Experienced Professionals</h4>{" "}
          </div>
        </Link>
        <Link to='/contact-us' className={classes.cardLink}  state={{selectedValue:'Students/ Internships'}}> 
        <div
          className={classes.graduate}
          style={{ backgroundColor: "rgba(50, 168, 115, 0.967)" }}
        >
          <img src="./images/student.png" alt="" className={classes.cardIcon} />
          <h4 style={{ fontWeight: "bold" }}>Students/ Internships</h4>{" "}
        </div>
        </Link>
        <Link to='/contact-us' className={classes.cardLink}  state={{selectedValue:'Explore Opportunities by Location'}}>
        <div
          className={classes.graduate}
          style={{ backgroundColor: "rgb(201, 61, 61)" }}
        >
          <img
            src="./images/location.png"
            alt=""
            className={classes.cardIcon}
          />
          <h4 style={{ fontWeight: "bold" }}>
            Explore Opportunities by Location
          </h4>{" "}
        </div>
        </Link>
      </div>
    </div>
  );
};

export default Career;
