import { useRoutes } from "react-router-dom";
import DashboardLayout from "./Layout/dashboard/DashboardLayout";
import Aboutus from "./pages/Aboutus";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contactus from "./pages/Contactus";
import Industries from "./pages/Industries";
import Technologies from "./pages/Technologies";
import Details from "./pages/Details";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Career from "./pages/Career";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Home /> },
        {
          path: "about-us",
          element: <Aboutus />,
        },
        {
          path: "services",
          element: <Services />,
        },
        {
          path: "services/:id",
          element: <Details page="Services" imgUrl="../images/web.jpg" />,
        },
        {
          path: "industries/:id",
          element: (
            <Details
              page="Industries"
              imgUrl="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*f-0MUEuaILWShigxdiqkjw.jpeg"
            />
          ),
        },
        {
          path: "technologies/:id",
          element: (
            <Details page="Technologies" imgUrl="../images/mobile-bi.jpg" />
          ),
        },
        {
          path: "industries",
          element: <Industries />,
        },
        {
          path: "technologies",
          element: <Technologies />,
        },
        {
          path: "contact-us",
          element: <Contactus />,
        },
        {
          path:"career",
          element:<Career />
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "terms-and-condition",
          element: <TermsConditions />,
        },
      ],
    },
  ]);
  return routes;
}
