import ContactBanner from "../Component/Contactus/ContactBanner";
import ContactForm from "../Component/Contactus/ContactForm";
import Maps from "../Component/Contactus/Maps";

const Contactus = () => {
  return (
    <>
      <div style={{ background: "#F5F5F5" }}>
        <ContactBanner />
        <ContactForm />
        <Maps />
      </div>
    </>
  );
};

export default Contactus;
