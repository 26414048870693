import { Helmet } from "react-helmet-async";
import "./App.css";
import SmoothScrollToTop from "./Reusable/ScrollToTop";
import Router from "./routes";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Enable BI | Business Intelligence and Outsourcing</title>
        <meta
          name="description"
          content="Welcome to Enable BI. We specialize in Business Intelligence, Outsourcing, and Web Development services to empower your business for the digital age."
        />
        <link rel="shortlink" href="https://enablebi.com/" />
        <link rel="canonical" href="https://enablebi.com/" />
        <meta property="og:locale" content="en" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Enable BI | Business Intelligence and Outsourcing"
        />
        <meta
          property="og:description"
          content="Welcome to Enable BI. We specialize in Business Intelligence, Outsourcing, and Web Development services to empower your business for the digital age."
        />
        <meta property="og:url" content="https://enablebi.com/" />
        <meta
          property="og:site_name"
          content="Enable BI | Business Intelligence and Outsourcing"
        />
        <meta
          property="article:modified_time"
          content="2023-09-30T10:17:55+00:00"
        />
        <meta
          property="og:image"
          content="https://enablebi.com/images/enablelogo.png"
        />
      </Helmet>
      <SmoothScrollToTop />
      <Router />
    </div>
  );
}

export default App;
