import SlickCarousel from "../Reusable/SlickCarousel";
import { industryList } from "../utils/constants";
import Banner from "../Reusable/Banner";

const Industries = () => {
  return (
    <>
      <Banner
        page="Industries"
        videoUri="https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Flying%20Papers%20Logo_1080p.mp4?alt=media&token=2a3661ae-a534-4d92-a230-fb7663696e8e&_gl=1*c0u0l1*_ga*MjAwNzk0NjY0NC4xNjY4MjQ1MjM2*_ga_CW55HF8NVT*MTY5NjAwNTgyMC4yNjMuMS4xNjk2MDA1ODc3LjMuMC4w"
      />
      <SlickCarousel
        type="industries"
        heading="Complex problems. Risk mitigation. Operational excellence."
        slideList={industryList}
      />
    </>
  );
};

export default Industries;
