import { Card } from "@mui/material";
import classes from "./DetailsCard.module.css";

const DetailCard = ({ data }) => {
  console.log("DetailCard:", data);
  return (
    <Card className={classes.detailsCard}>
      <div>
        <div>
          <img
            src={`../images/${data.coverImg || data.img}`}
            alt={data.title}
            className={classes.sectionImage}
          />
        </div>
        <div className={classes.contentOuter}>
          <h4 className={classes.detailsHeading}>{data.title}</h4>
          <p className={classes.detailsDescription}>{data.bigDesc}</p>
        </div>
      </div>
    </Card>
  );
};

export default DetailCard;
