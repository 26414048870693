import { Link } from "react-router-dom";
import classes from "./ContactBanner.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ContactBanner = () => {
  const titleStyles = {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    textAlign: "center",
    // background: "rgba(0, 0, 0, 0.7)", // Background color for better readability
    padding: "10px",
    color: "#fff", // Text color
  };

  return (
    <>
      <section className={`${classes.ContactBanner} main-banner bg-img`}>
        <div className="container">
          <div className={classes.heading} style={titleStyles}>
            <h3 className={classes.contactheading}>Contact us</h3>
            <div className={classes.backoption}>
              <Link to="/" className={classes.home}>
                Home
              </Link>
              <ArrowForwardIosIcon
                sx={{ fontSize: "12px" }}
                className={classes.arrowicon}
              />
              <Link to="" className={classes.contact}>
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactBanner;
