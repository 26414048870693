import { useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  industryList,
  serviceList,
  technologiesList,
} from "../../utils/constants";

const Navbar = () => {
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [industriesDropdownOpen, setIndustriesDropdownOpen] = useState(false);
  const [technologiesDropdownOpen, setTechnologiesDropdownOpen] =
    useState(false);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src="../images/enablelogo.png"
            alt="enablebi"
            className={classes.enablelogo}
          />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className={`${classes.navbarnav} navbar-nav ml-auto`}>
            <li className="nav-item active">
              <Link
                className={classes.navlink}
                to="/"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={classes.navlink}
                to="about-us"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                About
              </Link>
            </li>
            <li
              className={`nav-item dropdown ${
                servicesDropdownOpen ? "show" : ""
              }`}
              onMouseEnter={() => setServicesDropdownOpen(true)}
              onMouseLeave={() => setServicesDropdownOpen(false)}
            >
              <Link
                className={`${classes.navlink} dropdown-toggle`}
                to="services"
                id="navbarDropdownServices"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </Link>
              <div
                className={`dropdown-menu ${
                  servicesDropdownOpen ? "show" : ""
                }`}
                aria-labelledby="navbarDropdownServices"
                onMouseEnter={() => setServicesDropdownOpen(true)}
                onMouseLeave={() => setServicesDropdownOpen(false)}
              >
                {/* Dropdown items */}
                {serviceList.map((service) => (
                  <Link
                    key={service.title}
                    className={`${classes.dropdownitem} dropdown-item`}
                    to={`services/${service.link}`}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    {service.title}
                  </Link>
                ))}
              </div>
            </li>
            <li
              className={`nav-item dropdown ${
                industriesDropdownOpen ? "show" : ""
              }`}
              onMouseEnter={() => setIndustriesDropdownOpen(true)}
              onMouseLeave={() => setIndustriesDropdownOpen(false)}
            >
              <Link
                className={`${classes.navlink} dropdown-toggle`}
                to="industries"
                id="navbarDropdownIndustry"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Industries
              </Link>
              <div
                className={`dropdown-menu ${
                  industriesDropdownOpen ? "show" : ""
                }`}
                aria-labelledby="navbarDropdownIndustry"
                onMouseEnter={() => setIndustriesDropdownOpen(true)}
                onMouseLeave={() => setIndustriesDropdownOpen(false)}
              >
                {industryList.map((industry) => (
                  <Link
                    key={industry.title}
                    className={`${classes.dropdownitem} dropdown-item`}
                    to={`industries/${industry.link}`}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    {industry.title}
                  </Link>
                ))}
              </div>
            </li>
            <li
              className={`nav-item dropdown ${
                technologiesDropdownOpen ? "show" : ""
              }`}
              onMouseEnter={() => setTechnologiesDropdownOpen(true)}
              onMouseLeave={() => setTechnologiesDropdownOpen(false)}
            >
              <Link
                className={`${classes.navlink} dropdown-toggle`}
                to="technologies"
                id="navbarDropdownTech"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Technologies
              </Link>
              <div
                className={`dropdown-menu ${
                  technologiesDropdownOpen ? "show" : ""
                }`}
                aria-labelledby="navbarDropdownTech"
                onMouseEnter={() => setTechnologiesDropdownOpen(true)}
                onMouseLeave={() => setTechnologiesDropdownOpen(false)}
              >
                {technologiesList.map((tech) => (
                  <Link
                    key={tech.title}
                    className={`${classes.dropdownitem} dropdown-item`}
                    to={`technologies/${tech.link}`}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    {tech.title}
                  </Link>
                ))}
              </div>
            </li>
            <li className="nav-item">
              <Link
                className={classes.navlink}
                to="career"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Careers
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={classes.navlink}
                to="contact-us"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Contact Us
              </Link>
            </li>
          
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
