import { Grid, Stack, Typography } from "@mui/material";
import classes from "./Maps.module.css";

const Maps = () => {
  return (
    <>
      <Typography className={classes.maps}>Locations of Enable BI</Typography>
      <Grid spacing={2} container className={classes.Mapsbottom}>
        <Grid md={6}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d15514.088027632944!2d78.48910145729398!3d13.564888222394217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDMzJzUzLjYiTiA3OMKwMzAnMjcuMiJF!5e0!3m2!1sen!2sin!4v1695972264173!5m2!1sen!2sin"
            width="500"
            height="300"
            style={{ border: 0  }}
            allowfullscreen=""
            className={classes.maps1}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Enable BI"
          ></iframe>
        </Grid>
        <Grid md={6}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.794765249957!2d-79.7895157!3d43.6316304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b6b28dbdafa07%3A0x3ff72eaed25ab052!2s36%20Mugford%20Cres%2C%20Brampton%2C%20ON%20L6Y%200C3%2C%20Canada!5e0!3m2!1sen!2sin!4v1694417942739!5m2!1sen!2sin"
            width="500"
            height="300"
            style={{ border: 0 }}
            className={classes.maps2}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Enable BI"
          ></iframe>
        </Grid>
      </Grid>
    </>
  );
};

export default Maps;
