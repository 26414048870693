import Card from "@mui/material/Card";
import classes from "./ValuesCard.module.css";

const ValuesCard = () => {
  return (
    <Card className={classes.valuesCard}>
      <h4 className={classes.valuesHeading}>Our Values</h4>
      <ul className={classes.textList}>
        <li className={classes.cardText}>
          <span className={classes.textSpan}>Innovation: </span>Foster a culture
          of continuous innovation, encouraging employees to think creatively
          and push the boundaries of what is possible in software development.
        </li>
        <li className={classes.cardText}>
          <span className={classes.textSpan}>Quality: </span>Dedicated to
          delivering high-quality software and services, striving for excellence
          in all aspects of the company's work.
        </li>
        <li className={classes.cardText}>
          <span className={classes.textSpan}>Ethical Conduct: </span>Uphold
          ethical standards in all interactions, demonstrating integrity,
          responsibility, and respect in business practices.
        </li>
        <li className={classes.cardText}>
          <span className={classes.textSpan}>Transparency: </span>Maintain open
          and honest communication within the company, with customers, and with
          stakeholders, building trust and accountability.
        </li>
      </ul>
    </Card>
  );
};

export default ValuesCard;
