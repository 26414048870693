import Card from "@mui/material/Card";
import classes from "./AboutUsCard.module.css";
import ArticleIcon from "@mui/icons-material/Article";

const AboutUsCard = () => {
  return (
    <Card className={classes.cardMain}>
      <div className={classes.cardHeader}>
        <ArticleIcon />
        <h4 className={classes.cardHeading}>Get to know us better</h4>
      </div>
      <p className={classes.cardText}>
        At Enable BI Software Solutions Pvt. Ltd., we're more than just a
        business intelligence software company; we're your trusted partner in
        data-driven success. Get to know us better and discover how we can
        empower your organization to make informed decisions, maximize your
        data's potential, and drive growth. Our mission is clear: to provide
        exceptional business intelligence solutions that empower businesses like
        yours to harness the full power of their data. We're dedicated to:
        Delivering cutting-edge BI software tailored to your unique needs.
        Ensuring data accuracy, security, and privacy. Building a culture of
        customer-centricity, where your success is our top priority.
      </p>
    </Card>
  );
};

export default AboutUsCard;
