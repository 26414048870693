const serviceList = [
  {
    img: "Business Intelligence.png",
    coverImg: "bi_cover.jpeg",
    title: "Business Intelligence",
    desc: "Harness the power of data with our cutting-edge Business Intelligence solutions. Gain actionable insights, make data-driven decisions, and stay ahead of the competition.",
    bigDesc:
      "Business intelligence (BI) is a technology-driven process for analyzing data and delivering actionable information that helps executives, managers and workers make informed business decisions. As part of the BI process, organizations collect data from internal IT systems and external sources, prepare it for analysis, run queries against the data and create data visualizations, BI dashboards and reports to make the analytics results available to business users for operational decision-making and strategic planning. The ultimate goal of BI initiatives is to drive better business decisions that enable organizations to increase revenue, improve operational efficiency and gain competitive advantages over business rivals. To achieve that goal, BI incorporates a combination of analytics, data management and reporting tools, plus various methodologies for managing and analyzing data.",
    link: "business-intelligence",
  },
  {
    img: "Consulting & Outsourcing.png",
    coverImg: "outsourcing_cover.jpeg",
    title: "Consulting & Outsourcing",
    desc: "Streamline your operations and reduce costs with our outsourcing expertise. We provide a range of outsourcing solutions, including customer support, IT services etc",
    bigDesc:
      "Consultancy services involve the provision of specialized advice, expertise, and recommendations to clients seeking solutions to specific challenges or opportunities. Consultants, who are experts in their respective fields, work closely with individuals, businesses, or organizations to offer tailored strategies and insights that help clients make informed decisions, optimize processes, and achieve their objectives. On the other hand, outsourcing services entail the delegation of specific non-core business functions or tasks to external service providers. This practice allows companies to streamline operations, reduce costs, and enhance efficiency by leveraging the skills and resources of third-party specialists. Outsourcing commonly encompasses areas like IT services, customer support, manufacturing, and more, enabling organizations to focus on their core competencies while external providers handle delegated responsibilities.",
    link: "consulting-&-outsourcing",
  },
  {
    img: "Mobile Application.png",
    coverImg: "mobile_cover.jpeg",
    title: "Mobile Application",
    desc: "A mobile application, or app, is a software program designed for use on mobile devices such as smartphones and tablets to provide specific functionalities or services.",
    bigDesc:
      "A mobile application service refers to a software solution designed and developed for use on mobile devices, such as smartphones and tablets. These applications, often referred to as mobile apps, serve various purposes and cater to diverse needs, including productivity, entertainment, communication, and information access. Mobile app services encompass the entire lifecycle of app development, from conceptualization and design to coding, testing, deployment, and ongoing maintenance. These services are provided by developers and development teams, and they involve creating user-friendly, functional, and engaging applications that can be downloaded and installed on mobile devices through app stores or other distribution platforms. Mobile app services play a crucial role in meeting the demands of today's increasingly mobile-centric society, enabling businesses and individuals to access software tools and resources conveniently on the go.",
    link: "mobile-application",
  },
  {
    img: "Web Application.png",
    coverImg: "web_cover.jpg",
    title: " Web Application",
    desc: "Create a strong online presence with our web development services. From stunning websites to custom web applications, we bring your digital vision to life.",
    bigDesc:
      "A web application service refers to a software application that operates over the internet and can be accessed and used through a web browser, without the need for users to download or install any software on their local devices. These applications are hosted on remote servers and provide a wide range of functionalities, from online shopping and social networking to project management and collaboration tools. Web application services encompass the development, deployment, and maintenance of these web-based applications. They are designed to offer users a seamless and interactive experience, allowing them to perform tasks, access information, and engage in various activities via a web interface. Businesses and organizations often rely on web application services to deliver their products, services, and content to a global audience, making them an integral part of the digital landscape in today's interconnected world.",
    link: "web-application",
  },
];

const industryList = [
  {
    img: "banking.jpg",
    coverImg: "banking_cover.jpg",
    title: "Finance & Banking",
    desc: "The finance and banking industry encompasses the management, allocation, and movement of funds, serving as the backbone of economic systems worldwide.",
    bigDesc:
      "The finance and banking industry is a critical sector within the global economy that encompasses a wide range of financial institutions, services, and activities. It includes commercial banks, investment banks, credit unions, insurance companies, asset management firms, and other financial intermediaries. The primary purpose of this industry is to facilitate the flow of money and capital within the economy. It provides essential services such as accepting deposits, lending funds, managing investments, and mitigating financial risks. Furthermore, the finance and banking industry plays a pivotal role in supporting economic growth, enabling businesses and individuals to access funds for various purposes, and fostering financial stability through regulatory oversight. It is a dynamic and heavily regulated sector that is continuously evolving to adapt to changing market conditions, technological advancements, and global economic trends.",
    link: "finance",
  },
  {
    img: "insurance.jpg",
    coverImg: "insurance_cover.jpeg",
    title: "Insurance",
    desc: "The insurance industry manages risk by providing financial protection through policies for individuals and businesses against various potential losses.",
    bigDesc:
      "The insurance industry is a crucial sector within the financial services landscape that revolves around the business of risk management. It involves various insurance companies, providers, and intermediaries who offer a wide array of insurance products and services to individuals and organizations. These products range from life, health, property, and casualty insurance to specialized coverage like car insurance or cybersecurity insurance. The fundamental concept of insurance involves individuals or entities paying premiums to insurers in exchange for financial protection against specific risks or losses. In the event of an insured occurrence, the insurance company compensates the policyholder or beneficiary, thus providing financial security and peace of mind. The insurance industry is highly regulated and plays an essential role in supporting economic stability by spreading risk and providing a safety net for unforeseen events, encouraging investment, and facilitating commerce by reducing financial uncertainties.",
    link: "insurance",
  },
  {
    img: "manufacturing.jpg",
    coverImg: "manufacturing_cover.jpeg",
    title: "Manufacturing",
    desc: "The manufacturing industry involves the production of physical goods through various processes, contributing significantly to economic growth and development.",
    bigDesc:
      "The manufacturing industry is a critical sector of the economy that involves the production of physical goods through various processes, techniques, and technologies. It encompasses a wide range of activities, from the transformation of raw materials into finished products to the assembly and packaging of goods for distribution and sale. The manufacturing industry is diverse, with sectors such as automotive, electronics, aerospace, pharmaceuticals, and consumer goods, each with its own unique production methods and challenges. This industry is a major driver of economic growth, employment, and innovation, as it not only creates products for consumers and businesses but also fosters technological advancements and supply chain efficiencies. It plays a vital role in both local and global economies, contributing significantly to a nation's GDP, trade balance, and industrial development.",
    link: "manufacturing",
  },
  {
    img: "retail.jpg",
    coverImg: "retail_cover.png",
    title: "Retail",
    desc: "The retail industry encompasses businesses that sell products and services directly to consumers, shaping consumer trends and driving economic activity.",
    bigDesc:
      "The retail industry is a dynamic and diverse sector of the economy that encompasses businesses engaged in the sale of goods and services directly to consumers. This industry spans a wide range of formats, from brick-and-mortar stores and shopping malls to e-commerce platforms and mobile apps. Retailers procure products from manufacturers or wholesalers and make them available to the public for purchase, often providing a variety of choices and pricing options. The retail industry is a critical component of the economy, influencing consumer trends, employment, and economic growth. It plays a pivotal role in meeting the everyday needs and desires of individuals while also shaping the competitive landscape for businesses worldwide, driven by factors such as changing consumer preferences, technological advancements, and evolving market dynamics.",
    link: "retail",
  },
  {
    img: "healthcare.jpg",
    coverImg: "healthcare_cover.jpeg",
    title: "Healthcare",
    desc: "The healthcare industry provides medical services, treatments, and products to maintain and improve health, serving individuals and communities worldwide.",
    bigDesc:
      "The healthcare industry is a complex and multifaceted sector focused on the provision of medical services, treatment, and products to promote and maintain health, prevent illnesses, and address medical conditions and diseases. It encompasses a vast range of organizations and professionals, including hospitals, clinics, pharmaceutical companies, biotechnology firms, medical device manufacturers, insurance providers, and healthcare professionals such as doctors, nurses, and therapists. This industry is dedicated to improving the well-being of individuals and communities, with an emphasis on patient care, research, and innovation. It is characterized by its continuous efforts to advance medical knowledge, enhance patient outcomes, and adapt to the evolving healthcare needs of societies globally, making it a critical and highly regulated component of the global economy.",
    link: "healthcare",
  },
  {
    img: "telecommunications.jpg",
    coverImg: "tele_communication_cover.jpeg",
    title: "Mobile Communications",
    desc: "The mobile communications industry facilitates wireless communication via mobile devices, driving technological innovation and global connectivity.",
    bigDesc:
      "The mobile communications industry refers to the dynamic and rapidly evolving sector that provides wireless communication services to individuals and businesses through mobile devices such as smartphones, tablets, and wearable technology. It encompasses a wide range of players, including mobile network operators, device manufacturers, software developers, and infrastructure providers. This industry is at the forefront of technological innovation, driving advancements in wireless networks (such as 5G), mobile operating systems, and a vast ecosystem of applications and services. It plays a pivotal role in connecting people globally, enabling voice calls, text messaging, internet access, and a multitude of mobile applications that have transformed the way we communicate, work, and live. The mobile communications industry continues to reshape our world by fostering connectivity, facilitating digital transformation, and enabling new opportunities across various sectors, including healthcare, finance, entertainment, and beyond.",
    link: "mobile-communications",
  },
  {
    img: "education.jpg",
    coverImg: "education_cover.jpeg",
    title: "Education",
    desc: "The education industry is dedicated to imparting knowledge and skills through institutions and platforms, shaping personal and societal development.",
    bigDesc:
      "The education industry encompasses a diverse and vital sector dedicated to the acquisition and dissemination of knowledge and skills. It includes institutions such as schools, colleges, universities, vocational training centers, and e-learning platforms. These organizations provide formal and informal education, training, and development opportunities to individuals of all ages, from early childhood education to lifelong learning and professional development. The education industry plays a fundamental role in shaping the intellectual, social, and economic development of societies by preparing individuals for careers, fostering critical thinking, and promoting cultural awareness. In today's digital age, technology has also significantly transformed this industry, enabling remote and online learning, personalized education, and innovative teaching methods. The education industry is a cornerstone of societal progress, fostering human potential and equipping individuals with the skills and knowledge needed to thrive in an ever-changing world.",
    link: "education",
  },
  {
    img: "logistics.jpg",
    coverImg: "logistics_cover.webp",
    title: "Travel and Logistics",
    desc: "The travel and logistics industry encompasses services that facilitate transportation, accommodation, and goods movement, driving global connectivity and trade.",
    bigDesc:
      "The travel and logistics industry is a multifaceted sector that encompasses the planning, organization, and facilitation of transportation, accommodation, and related services for individuals and goods. It includes a wide array of businesses and services, such as airlines, hotels, cruise lines, transportation companies, freight forwarders, travel agencies, and more. This industry plays a pivotal role in enabling the movement of people and goods, both domestically and internationally, fostering tourism, trade, and commerce. Additionally, it encompasses the management of supply chains, ensuring the efficient movement of goods from manufacturers to consumers. With the advent of technology, the travel and logistics industry has undergone significant digital transformation, with online booking platforms, real-time tracking, and data analytics enhancing convenience, efficiency, and customer experiences. Overall, this industry is integral to global connectivity, economic growth, and the facilitation of the modern way of life.",
    link: "travel-&-logistics",
  },
];

const technologiesList = [
  {
    img: "datascience.jpg",
    coverImg: "datascience_cover.jpeg",
    title: "Data Science",
    desc: "The data science industry leverages advanced analytics and machine learning to extract insights from data, driving informed decision-making.",
    bigDesc:
      "Data science technology refers to a multidisciplinary field that combines various tools, techniques, and methodologies to extract valuable insights, patterns, and knowledge from large and complex datasets. It encompasses the use of advanced statistical analysis, machine learning algorithms, data visualization, and domain expertise to interpret data and make informed decisions. Data science technology plays a pivotal role in addressing real-world challenges across industries, enabling organizations to uncover hidden trends, optimize processes, predict future outcomes, and derive actionable information from their data. It is a critical component in modern data-driven decision-making, allowing businesses, researchers, and policymakers to harness the power of data to drive innovation, enhance competitiveness, and solve complex problems.",
    link: "data-science",
  },
  {
    img: "java.jpg",
    coverImg: "java_cover.jpeg",
    title: "Java",
    desc: "The Java industry is a software development ecosystem utilizing the Java programming language for a wide range of applications and services.",
    bigDesc:
      "Java technology is a versatile and widely-used programming platform and ecosystem that offers a range of tools and resources for developing and running software applications. It is renowned for its platform independence, thanks to the Java Virtual Machine (JVM), which allows Java programs to run on various operating systems without modification. Java is appreciated for its simplicity, portability, and robustness, making it a preferred choice for building a diverse range of applications, including web, mobile, enterprise, and embedded systems. It features a rich set of libraries and frameworks, fostering rapid development and reducing development time. Additionally, Java's strong emphasis on security and its support for multi-threading make it a suitable choice for mission-critical applications and large-scale systems. This technology continues to be a cornerstone in the software development world, powering everything from Android mobile apps to server-side enterprise applications.",
    link: "java",
  },
  {
    img: "python.jpg",
    coverImg: "python_cover.png",
    title: "Python",
    desc: "The Python industry thrives on the versatile Python programming language, powering diverse applications, from web development to data analysis and AI.",
    bigDesc:
      "Python technology refers to a versatile and high-level programming language known for its simplicity and readability. It has gained immense popularity in various domains, including web development, data analysis, artificial intelligence, scientific computing, and automation. Python's extensive standard library and a vast ecosystem of third-party packages and frameworks make it a powerful choice for developers seeking to build efficient and feature-rich applications with ease. Its clean and expressive syntax encourages rapid development and allows developers to focus on problem-solving rather than intricate code details. Python's cross-platform compatibility and a strong online community contribute to its widespread adoption, making it a favored language for both beginners and experienced programmers, and a driving force behind innovations in various technology fields.",
    link: "python",
  },
  {
    img: "sql.jpg",
    coverImg: "sql_cover.png",
    title: "SQL",
    desc: "The SQL industry revolves around structured query language, the foundation for managing and querying relational databases in various sectors.",
    bigDesc:
      "SQL (Structured Query Language) technology is a specialized programming language designed for managing and manipulating relational databases. It serves as a fundamental tool in the world of data management, allowing users to interact with databases to perform tasks such as querying data, inserting, updating, and deleting records, and defining the structure of databases (creating tables, indexes, and constraints). SQL is crucial for extracting valuable insights and information from structured data and is used across a wide range of industries and applications, including business intelligence, web development, e-commerce, and more. Its standardized syntax and extensive support in database management systems (DBMS) like MySQL, Oracle, SQL Server, and PostgreSQL make SQL an essential technology for anyone working with data storage and retrieval.",
    link: "sql",
  },
  {
    img: "sap.jpg",
    coverImg: "sap_cover.jpeg",
    title: "SAP",
    desc: "The SAP industry focuses on enterprise resource planning (ERP) solutions and software, aiding businesses in optimizing their operations and processes.",
    bigDesc:
      "SAP (Systems, Applications, and Products) technology refers to a comprehensive suite of software solutions and enterprise resource planning (ERP) systems developed by the German company SAP SE. SAP technology is designed to facilitate and optimize various business processes within organizations, including finance, human resources, supply chain management, customer relationship management, and more. It provides a unified platform for data integration, enabling businesses to streamline operations, improve decision-making, and enhance overall efficiency. SAP technology is known for its scalability, flexibility, and customizability, making it suitable for businesses of all sizes and industries. It plays a pivotal role in helping companies manage their resources, reduce costs, and gain a competitive edge in today's complex and data-driven business environment.",
    link: "sap",
  },
];

const subCardData = [
  {
    id: "1",
    title: "Our Services",
    list: serviceList,
  },
  {
    id: "2",
    title: "Industries",
    list: industryList,
  },
  {
    id: "3",
    title: "Technologies",
    list: technologiesList,
  },
];

const carouselImages = [
  "https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2972&q=80",
  "https://images.unsplash.com/photo-1480944657103-7fed22359e1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3132&q=80",
  "../images/mobile-bi.jpg",
  // "https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
  "../images/web.jpg",
];

const videoList = [
  "https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Solid%20Logo%20Reveal_1080p.mp4?alt=media&token=e0792476-e72e-4b01-a4dc-b4f03a89b29c&_gl=1*pnpjc7*_ga*MjAwNzk0NjY0NC4xNjY4MjQ1MjM2*_ga_CW55HF8NVT*MTY5NjAwNTgyMC4yNjMuMS4xNjk2MDA2OTgyLjU5LjAuMA..",
  "https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Flying%20Papers%20Logo_1080p.mp4?alt=media&token=2a3661ae-a534-4d92-a230-fb7663696e8e&_gl=1*16s0pit*_ga*MjAwNzk0NjY0NC4xNjY4MjQ1MjM2*_ga_CW55HF8NVT*MTY5NjAwNTgyMC4yNjMuMS4xNjk2MDA3MDA1LjM2LjAuMA..",
  "https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Evolving%20Sketch%20Logo%20Reveal_1080p%20(1).mp4?alt=media&token=b2bdc755-796a-418a-8c98-82ffe8217877&_gl=1*64kdg8*_ga*MjAwNzk0NjY0NC4xNjY4MjQ1MjM2*_ga_CW55HF8NVT*MTY5NjAwNTgyMC4yNjMuMS4xNjk2MDA3MDE5LjIyLjAuMA..",
  "https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Clean%20LineStyle%20Logo_1080p%20(1).mp4?alt=media&token=3c6c7040-ea6b-4655-8284-5c6238b2f46e&_gl=1*1bqm8jp*_ga*MjAwNzk0NjY0NC4xNjY4MjQ1MjM2*_ga_CW55HF8NVT*MTY5NjAwNTgyMC4yNjMuMS4xNjk2MDA3MDM1LjYuMC4w",
];

export {
  serviceList,
  industryList,
  technologiesList,
  subCardData,
  carouselImages,
  videoList,
};
