import AboutUsCard from "../Reusable/AboutUsCard";
import MissionCard from "../Reusable/MissionCard";
import ValuesCard from "../Reusable/ValuesCard";
import classes from "./Aboutus.module.css";
import Grid from "@mui/material/Grid";
import Banner from "../Reusable/Banner";

const Aboutus = () => {
  return (
    <>
      <section>
        <Banner
          page="About Us"
          videoUri="https://firebasestorage.googleapis.com/v0/b/enable-bi.appspot.com/o/Solid%20Logo%20Reveal_1080p.mp4?alt=media&token=e0792476-e72e-4b01-a4dc-b4f03a89b29c&_gl=1*12l3blg*_ga*MjA0NjMzNTQwMC4xNjYxODY0MzU1*_ga_CW55HF8NVT*MTY5NjAwMzk5MC41NC4xLjE2OTYwMDM5OTQuNTYuMC4w"
        />
        <div className={classes.aboutUsCardMain}>
          <AboutUsCard />
        </div>
        <Grid container spacing={2} className={classes.subCards}>
          <Grid item xs={12} md={6} lg={6}>
            <MissionCard />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ValuesCard />
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default Aboutus;
