import { Link } from "react-router-dom";
import {
  industryList,
  serviceList,
  technologiesList,
} from "../../utils/constants";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <>
      <footer className={classes.footermain}>
        <div className={`${classes.footerdiv} row container`}>
          <div className="col-md-3 col-lg-3">
            <h6 className={classes.aboutheading}>About us</h6>
            <p className={classes.aboutcontent}>
              Welcome to Enable BI Software Solutions Pvt. Ltd. Your one-stop
              destination for comprehensive software solutions that drive
              business success. We are a forward-thinking software company that
              specializes in Business Intelligence, Outsourcing, and Web
              Development services. With a passion for innovation and a
              commitment to excellence, we're here to empower your business for
              the digital age. Enable BI is a trailblazing software company
              that's passionate about transforming ideas into innovative
              solutions.
            </p>
          </div>
          <div className="col-md-2 col-lg-2">
            <h6 className={classes.aboutheading}>Services</h6>
            <div className="serviceslink">
              <ul className={classes.techul}>
                {serviceList.map((service) => (
                  <li className={classes.techli} key={service.title}>
                    <Link
                      to={`services/${service.link}`}
                      className={classes.links}
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <img
                        src="../images/arrow-orange.svg"
                        alt="right arrow"
                        className={classes.arrow}
                      />
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-lg-2">
            <h6 className={classes.aboutheading}>
              {/* <img
                src="../images/arrow right.svg"
                alt=""
                className={classes.arrow}
              /> */}
              Industries
            </h6>
            <div className="industrylink">
              <ul className={classes.techul}>
                {industryList.map((industry) => (
                  <li className={classes.techli} key={industry.title}>
                    <Link
                      to={`industries/${industry.link}`}
                      className={classes.links}
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <img
                        src="../images/arrow-orange.svg"
                        alt="arrow-orange"
                        className={classes.arrow}
                      />
                      {industry.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-lg-2">
            <h6 className={classes.aboutheading}>Technologies</h6>
            <div className="techlink">
              <ul className={classes.techul}>
                {technologiesList.map((tech) => (
                  <li className={classes.techli} key={tech.title}>
                    <Link
                      to={`technologies/${tech.link}`}
                      className={classes.links}
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <img
                        src="../images/arrow-orange.svg"
                        alt="arrow-orange"
                        className={classes.arrow}
                      />
                      {tech.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-lg-3">
            <h6 className={classes.aboutheading}>Get in Touch</h6>
            <div className="serviceslink">
              <ul className={classes.techul}>
                <li className={classes.techli}>
                  <img
                    src="../images/location.svg"
                    alt="location"
                    className={classes.location}
                  />
                  India Address: 3/161-19-7-6-1, 2nd Main, SBI Colony,
                  Madanapalli, Annamayya (DT), A.P - 517325
                </li>
                <li className={classes.techli}>
                  <img
                    src="../images/location.svg"
                    alt="location"
                    className={classes.location}
                  />
                  Canada Address: 36 Mugford cres, Brampton, ON, L6Y6A7
                </li>
                <li className={classes.techli}>
                  <a
                    href="tel:+917259589758"
                    style={{ textDecoration: "none", color: "#d6d6d8" }}
                  >
                    <img
                      src="../images/phone.svg"
                      alt="phone"
                      className={classes.location}
                    />
                    +91 7259589758
                  </a>
                </li>
                <li className={classes.techli}>
                  <a
                    href="mailto:info@enablebi.com"
                    style={{ textDecoration: "none", color: "#d6d6d8" }}
                  >
                    <img
                      src="../images/email.svg"
                      alt="email"
                      className={classes.location}
                    />
                    info@enablebi.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`${classes.socialcopyrights} container`}>
          <div className="row py-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <p className={classes.copy}>Copyright @ 2023 </p>
                  <p className={classes.copy}>
                    Enable BI Software Solutions Pvt. Ltd.{" "}
                  </p>
                  <p className={classes.copy}>Make strides with us. </p>
                </div>
                <div className="col-md-6">
                  <div className={classes.privacy}>
                    <a
                      href="privacy-policy"
                      className={`${classes.copy} ${classes.textUnderline}`}
                    >
                      Privacy Policy
                    </a>
                    <a
                      href="terms-and-condition"
                      className={`${classes.copy} ${classes.textUnderline}`}
                    >
                      Terms and Conditions{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`${classes.socialmedia} row`}>
                <a href="">
                  <i
                    className={`${classes.facebook} fa-brands fa-facebook-f`}
                  ></i>
                </a>
                <a href="">
                  {" "}
                  <i className={`${classes.twitter} fa-brands fa-twitter`}></i>
                </a>
                <a href="">
                  <i className={`${classes.google} fa-brands fa-google`}></i>
                </a>
                <a href="">
                  <i className={`${classes.youtube} fa-brands fa-youtube`}></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
