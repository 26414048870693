import { useEffect, useRef, useState } from "react";
import classes from "./AboutDesc.module.css";
import { videoList } from "../../utils/constants";

const AboutDesc = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleVideoLoaded = () => {
      // Play the video after it has loaded
      video.play();
    };

    const handleVideoEnd = () => {
      // Increment the current video index or loop back to the beginning
      const nextVideoIndex =
        currentVideoIndex < videoList.length - 1 ? currentVideoIndex + 1 : 0;
      setCurrentVideoIndex(nextVideoIndex);

      // Load the next video and listen for the 'loadeddata' event to play it
      video.src = videoList[nextVideoIndex];
    };

    // Add event listeners for the 'ended' and 'loadeddata' events
    video.addEventListener("ended", handleVideoEnd);
    video.addEventListener("loadeddata", handleVideoLoaded);

    // Load and play the initial video
    video.src = videoList[currentVideoIndex];
    video.load();

    // Clean up the event listeners when the component unmounts
    return () => {
      video.removeEventListener("ended", handleVideoEnd);
      video.removeEventListener("loadeddata", handleVideoLoaded);
    };
  }, [currentVideoIndex]);

  return (
    <>
      <div className={classes.main}>
        <div className={`${classes.aboutmain} row container m-auto`}>
          <div className={`${classes.aboutdec} col-md-12  container`}>
            <h3 className={classes.aboutheading}>
              <i className={`${classes.file} fa-solid fa-file-lines`}></i>About
            </h3>
            <p className={classes.aboutpara}>
              Welcome to Enable BI Software Solutions Pvt. Ltd. Your one-stop
              destination for comprehensive software solutions that drive
              business success. We are a forward-thinking software company that
              specializes in Business Intelligence, Outsourcing, and Web
              Development services. With a passion for innovation and a
              commitment to excellence, we're here to empower your business for
              the digital age. Enable BI is a trailblazing software company
              that's passionate about transforming ideas into innovative
              solutions.
            </p>
            <div className={`${classes.whoweare} row`}>
              <div className={`${classes.whowearecontent} col-md-6`}>
                <h3 className={classes.aboutheading}>Who we are</h3>
                <p className={classes.aboutpara}>
                  We are a team of dedicated professionals, each bringing a
                  unique set of skills and experiences to the table. Our diverse
                  team includes Business Intelligence experts, software
                  developers, designers, and outsourcing specialists, all united
                  by a common goal: to deliver exceptional results for our
                  clients.
                </p>
              </div>
              <div className={`${classes.whoweareimg} col-md-6`}>
                {/* <img
                  src="../images/whoweare.jpg"
                  alt=""
                  className={classes.whoweareimage}
                /> */}
                <video ref={videoRef} muted autoPlay playsInline>
                  <source
                    src={videoList[Math.floor(Math.random() * 4)]}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutDesc;
