import AboutDesc from "../Component/Homepage/AboutDesc";
import HardWorking from "../Component/Homepage/HardWorking";
import DemoCarousel from "../Reusable/DemoCarousel";
// import HomeCarousel from "../Reusable/HomeCarousel";
import SlickCarousel from "../Reusable/SlickCarousel";
import { serviceList } from "../utils/constants";

const Home = () => {
  return (
    <>
      <div style={{ background: "#F5F5F5" }}>
        {/* <HomeCarousel /> */}
        <div className="example-container">
          <DemoCarousel />
        </div>
        <SlickCarousel
          type="services"
          heading="What we do"
          slideList={serviceList}
        />
        <AboutDesc />
        <HardWorking />
      </div>
    </>
  );
};

export default Home;
