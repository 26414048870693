import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

function SmoothScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scroll.scrollToTop({
      duration: 800, // You can adjust the duration to control the scrolling speed
      smooth: "easeInOutQuart", // You can use different easing functions for smoothness
    });
  }, [pathname]);

  return null;
}

export default SmoothScrollToTop;
